import React from "react"
import SEO from "../components/seo"
import Landing from "../components/landing"
import Root from "../components/root"

const IndexPage = () => (
  <Root>
    <SEO
      title="Developer Dave"
      description="The official domain of Developer Dave, a skilled software engineer with years of experience in shipping quality code to production."
      author="Dave Mariano"
    />
    <Landing />
  </Root>
)

export default IndexPage
