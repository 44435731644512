import React from "react";
import styled from 'styled-components';
import identicon from "../images/identicon_crimson.png";
import Button from "./button";
import theme from "../constants/theme";
import SECTIONS from "../constants/sections";

const RootWrapper = styled.div`
  background: ${props => props.theme.body.transparentBackground};
  height: 100vh;
  width: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Identicon = styled.img`
  height: 50%;
  margin: 2em;
`;

const TitleDiv = styled.div`
  margin: 0;
  text-align: center;
  padding-bottom: 3rem;
`;

const Title = styled.div`
  font-size: 3rem;
  
`;

const SubTitle = styled.div`
  font-size: 1.2rem;
`;

const Nav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const Landing = () => (
    <RootWrapper>
      <Wrapper>
        <Identicon alt="Dave's Identicon" src={identicon} />
        <TitleDiv>
          <Title>developer.dave</Title>
          <SubTitle>Lvl 9 Software Engineer</SubTitle>
        </TitleDiv>

        <Nav>
          <Button to={SECTIONS.about.url} size="large" color={theme.nav.about}>{SECTIONS.about.label}</Button>
          <Button to={SECTIONS.experience.url} size="large" color={theme.nav.experience}>{SECTIONS.experience.label}</Button>
          <Button to={SECTIONS.contact.url} size="large" color={theme.nav.contact}>{SECTIONS.contact.label}</Button>
        </Nav>
      </Wrapper>
    </RootWrapper>
);

export default Landing;
